import {
  CdkDrag,
  CdkDragDrop,
  CdkDropList,
  copyArrayItem,
} from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { DeleteFromPlaylistDialogComponent } from '@designage/app/playlist-sequence/delete-from-playlist-dialog/delete-from-playlist-dialog.component';
import {
  ResourceGroupUserInput,
  UserForResourceGroupUserListFragment,
} from '@designage/gql';
import { DesignageDataTableComponent } from '@desquare/components/common/src/designage-data-table/designage-data-table.component';
import {
  IDesignageDataTableColumns,
  IPlaylistForResourceGroup,
  IPlaylistForResourceGroupPlaylistList,
  IResourceGroup,
  IUserForResourceGroup,
} from '@desquare/interfaces';
import { ResourceGroupService } from '@desquare/services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { cloneDeep } from 'lodash';
import { BehaviorSubject, EMPTY, map, switchMap, take, tap } from 'rxjs';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    DesignageDataTableComponent,
    TranslateModule,
    CdkDropList,
  ],
  selector: 'app-resource-group-content',
  templateUrl: './resource-group-content.component.html',
  styleUrls: ['./resource-group-content.component.scss'],
  // providers: [ResourceGroupService],
})
export class ResourceGroupContentComponent implements OnInit {
  _resourceGroup = new BehaviorSubject<IResourceGroup | null>(null);

  get resourceGroup(): IResourceGroup | null {
    return this._resourceGroup.value;
  }
  resourceGroup$ = this._resourceGroup.asObservable();
  @Input('resourceGroup') set resourceGroup(
    value: IResourceGroup | null | undefined,
  ) {
    this._resourceGroup.next(value ?? null);

    // save for reset (cancel update)
    this.resourceGroupInitialValue = cloneDeep(value) ?? null;
  }
  resourceGroupInitialValue: IResourceGroup | null = null;

  @Input() isEditing = false;
  @Input() enableEditing = true;

  resourceGroupUsers$ = this.resourceGroup$.pipe(
    map((resourceGroup) => resourceGroup?.users ?? []),
  );

  resourceGroupPlaylists$ = this.resourceGroup$.pipe(
    map((resourceGroup) => resourceGroup?.playlists ?? []),
  );

  playlistTableId$ = this.resourceGroup$.pipe(
    map((resourceGroup) => resourceGroup?.id + '-playlist-table'),
  );

  userTableId$ = this.resourceGroup$.pipe(
    map((resourceGroup) => resourceGroup?.id + '-user-table'),
  );

  @Output() deleteResourceGroup = new EventEmitter<string>();
  @Output() editResourceGroup = new EventEmitter<string | null>();

  userTableColumns: IDesignageDataTableColumns[] = [
    {
      fieldName: 'displayName',
      type: 'string',
      name: 'USER_NAME',
      visible: 'mandatory',
    },
    {
      fieldName: 'canWrite',
      type: 'boolean',
      name: 'CAN_WRITE',
      visible: 'mandatory',
      disableSorting: true,
    },
  ];

  playlistTableColumns: IDesignageDataTableColumns[] = [
    {
      fieldName: 'name',
      type: 'string',
      name: 'PLAYLIST',
      visible: 'mandatory',
    },
  ];

  updateResourceGroupInput$ =
    new BehaviorSubject<ResourceGroupUserInput | null>(null);

  constructor(
    private modalService: NgbModal,
    private resourceGroupService: ResourceGroupService,
  ) {}

  ngOnInit(): void {}

  onDelete() {
    const modal = this.modalService.open(DeleteFromPlaylistDialogComponent);
    modal.componentInstance.messagePrompt = 'PERMISSION_GROUP_DELETE_PROMTP';
    modal.result
      .then((res) => {
        if (this.resourceGroup)
          this.deleteResourceGroup.emit(this.resourceGroup.id);

        if (this.isEditing) {
          this.isEditing = false;
          this.editResourceGroup.emit(null);
        }
      })
      .catch(() => {});
  }

  onEdit() {
    this.isEditing = true;
    this.editResourceGroup.emit(this.resourceGroup?.id ?? null);
  }

  onEditCancel() {
    this.isEditing = false;
    this.editResourceGroup.emit(null);

    this._resourceGroup.next(cloneDeep(this.resourceGroupInitialValue));
  }

  onEditSave() {
    this.isEditing = false;
    this.editResourceGroup.emit(null);

    this.resourceGroup$
      .pipe(
        take(1),
        switchMap((resourceGroup) => {
          if (!resourceGroup) return EMPTY;

          const { id, users, playlists } = resourceGroup;

          // transform to ResourceGroupUserInput[]
          const resourceGroupUserInputList: ResourceGroupUserInput[] =
            users.map(({ id, canWrite }) => {
              return {
                id,
                canWrite,
              };
            });

          return this.resourceGroupService.updateResourceGroup({
            id,
            playlistIds: playlists.map(({ id }) => id),
            users: resourceGroupUserInputList,
          });
        }),
      )
      .subscribe();
  }

  onPlaylistDataDropped(
    event: CdkDragDrop<
      IPlaylistForResourceGroup[],
      IPlaylistForResourceGroup[] | IPlaylistForResourceGroupPlaylistList[],
      IPlaylistForResourceGroupPlaylistList
    >,
  ) {
    // guard statements
    if (
      // exit if resourceGroup is empty
      !this.resourceGroup ||
      // exit if the dropped is from itself
      event.previousContainer === event.container ||
      // exit if already connected
      event.item.data.connectedResourceGroupName ||
      // exit if the item to be drop is already in the list
      this.checkByIdIfAlreadyInTheList(
        event.item.data,
        this.resourceGroup?.playlists ?? [],
      )
    )
      return;

    // transform to IPlaylistForResourceGroup
    const { id, name } = event.item.data;
    const playlistDropped: IPlaylistForResourceGroup = {
      id,
      name,
    };

    this.resourceGroup$
      .pipe(
        take(1),
        tap((_resourceGroup) => {
          const resourceGroup = cloneDeep(_resourceGroup);

          if (!resourceGroup) return EMPTY;
          const { playlists } = resourceGroup;

          // update the playlist list of resource group
          playlists.splice(event.currentIndex, 0, playlistDropped);

          // update resource group in the UI
          this._resourceGroup.next(cloneDeep(resourceGroup));
        }),
      )
      .subscribe();
  }

  onPlaylistTableRowDelete(event: IPlaylistForResourceGroup) {
    if (!this.resourceGroup) return;

    const playlistToDelete = event;

    // get current state of the resource group
    this.resourceGroup$
      .pipe(
        take(1),
        tap((resourceGroup) => {
          if (!resourceGroup) return EMPTY;
          const { playlists } = resourceGroup;

          // update the playlist list of resource group
          playlists.splice(
            playlists.findIndex(({ id }) => id === playlistToDelete.id),
            1,
          );

          // update the resource group UI
          this._resourceGroup.next(cloneDeep(resourceGroup));
        }),
      )
      .subscribe();
  }

  onUserDataDropped(
    event: CdkDragDrop<
      IUserForResourceGroup[],
      UserForResourceGroupUserListFragment[] | IUserForResourceGroup[],
      UserForResourceGroupUserListFragment | IUserForResourceGroup
    >,
  ) {
    // guard statements
    if (
      // exit if resourceGroup is empty
      !this.resourceGroup ||
      // exit if the dropped is from itself
      event.previousContainer === event.container ||
      // exit if item dropped is already in the list
      this.checkByIdIfAlreadyInTheList(
        event.item.data,
        this.resourceGroup?.users ?? [],
      )
    )
      return;

    this.resourceGroup$
      .pipe(
        take(1),
        tap((resourceGroup) => {
          if (!resourceGroup) return EMPTY;

          const { users } = resourceGroup;

          // if item dropped is UserForResourceGroupUserListFragment type
          // transform to UserForResourceGroupFragment
          const userDropped: IUserForResourceGroup = {
            ...event.item.data,
            canWrite:
              'canWrite' in event.item.data ? event.item.data.canWrite : false,
          };

          // append the user
          users.push(userDropped);

          // push updated resource group
          this._resourceGroup.next(cloneDeep(resourceGroup));
        }),
      )
      .subscribe();
  }

  onUserTableRowDelete(event: IUserForResourceGroup) {
    // guard statements
    if (
      // exit if resourceGroup is empty
      !this.resourceGroup
    )
      return;

    this.resourceGroup$
      .pipe(
        take(1),
        tap((resourceGroup) => {
          if (!resourceGroup) return EMPTY;

          const { users } = resourceGroup;
          const userToDelete = event;

          // remove the user from the list of users in resource group

          users.splice(users.indexOf(userToDelete), 1);

          this._resourceGroup.next(cloneDeep(resourceGroup));
        }),
      )
      .subscribe();
  }

  onUserTableRowCheckboxChange(event: {
    row: unknown;
    event: MatCheckboxChange;
  }) {
    const {
      id: userId,
      displayName,
      canWrite,
    } = event.row as IUserForResourceGroup;

    this.resourceGroup$
      .pipe(
        take(1),
        tap((resourceGroup) => {
          if (!resourceGroup) return EMPTY;

          const { users } = resourceGroup;

          const targetIndex = users.findIndex((user) => user.id === userId);

          // update user
          users[targetIndex].canWrite = canWrite;

          this._resourceGroup.next(cloneDeep(resourceGroup));
        }),
      )
      .subscribe();
  }

  resetResourceGroup$() {
    this._resourceGroup.next(this.resourceGroupInitialValue);
  }

  checkByIdIfAlreadyInTheList(object: any, list: any[]): boolean {
    if (!('id' in object) && list.length === 0 && !('id' in list[0]))
      return false;

    const alreadyInTheList =
      list.findIndex((item) => item.id === object.id) !== -1 ? true : false;

    return alreadyInTheList;
  }
}
