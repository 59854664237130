<div class="modal-header">
  <div class="w-100">
    <h4 class="text-center">
      {{ 'DEVICE' | translate }}: {{ device?.name }} - {{ statusDay | date }}
    </h4>
  </div>
  <button
    tabindex="-1"
    type="button"
    class="btn-close btn-close-white"
    data-bs-dismiss="modal"
    aria-label="Close"
    (click)="modal.dismiss()"
  ></button>
</div>
<div class="modal-body">
  <div class="d-flex flex-column">
    @if (deviceFullStatusData$ | async; as statusData) {
      <div class="d-flex align-items-center">
        <designage-echart
          class="w-75"
          [chartType]="ChartType.UPTIMEFULL"
          [data]="statusData"
          [height]="250"
          (onChartClick)="onChartClick($event)"
        ></designage-echart>
        <designage-echart
          class="w-100"
          [chartType]="ChartType.UPTIME"
          [data]="statusData"
          [dayZoom]="statusDay"
        ></designage-echart>
      </div>
      <div class="h-100 overflow-y-auto overflow-x-hidden">
        <div class="d-flex flex-column justify-content-center">
          <ng-container *ngIf="deviceTempDataForEchart$ | async as tempData">
            <ng-container *ngIf="tempData.length > 0">
              <hr />
              <h4 class="text-center">{{ 'SYSTEM_TEMP' | translate }}</h4>
              <designage-echart
                [chartType]="ChartType.TEMP"
                [data]="tempData"
                [height]="200"
                [dayZoom]="statusDay"
              ></designage-echart>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="deviceCpuDataForEchart$ | async as cpuData">
            <ng-container *ngIf="cpuData.length > 0">
              <hr />
              <h4 class="text-center">{{ 'CPU_USAGE' | translate }}</h4>
              <designage-echart
                [chartType]="ChartType.CPU"
                [data]="cpuData"
                [height]="200"
                [dayZoom]="statusDay"
              ></designage-echart>
            </ng-container>
          </ng-container>
        </div>
      </div>
    }
  </div>
</div>
<div class="modal-footer">
  {{ 'APPLET_VERSION' | translate }}: {{ device?.deviceInfo?.appletVersion }} |
  {{ 'APPLICATION_TYPE' | translate }}:
  {{ device?.deviceInfo?.applicationType | titlecase }}
  {{ device?.deviceInfo?.applicationVersion }} | {{ 'MODEL' | translate }}:
  {{ device?.deviceInfo?.model }}
</div>
