@if (loading) {
  <app-loader class="loader" [message]="loaderMessage" />
} @else {
  <div class="d-flex flex-column h-100">
    @if (profile) {
      <app-user-selection
        [profileId]="profile.id"
        [(selectedUsers)]="selectedUsers"
        [enableSelectionActionButtons]="true"
        selectActionButtonText="ADD"
        deselectActionButtonText="REMOVE"
        (userDeselect)="onUserDeselected($event)"
        (rowActivate)="navigateToUser($event)"
        (openInviteDialogClicked)="openInviteDialog()"
        [profileUsersUpdated]="profileUsersUpdated"
      />
    }
    @if (lastInvitedUrl && lastInvitedUrl.length > 0) {
      <div class="mt-2 card">
        <div class="card-header">
          <h5>Generated invite URL</h5>
        </div>
        <div class="card-body">
          <div class="card-text">
            <p>
              The invite link is sent to the user's email address. The user can
              then use the link to register and join this profile.
              <br />
              If you want to send this link in another way (like with What's App
              or your own email program) you can copy this link from below.
              <br />
              <br />
              The link is valid for 48 hours and can only be used once.
            </p>
          </div>
        </div>
        <div class="card-body">
          <div class="card-text font-monospace p-2">
            {{ lastInvitedUrl }}
          </div>
          @if (lastInvitedUrl) {
            <copy-to-clipboard [cbcontent]="lastInvitedUrl" [button]="true" />
          }
        </div>
      </div>
    }
  </div>
}
