import {
  Component,
  OnInit,
  OnDestroy,
  inject,
  DestroyRef,
  ChangeDetectionStrategy,
  computed,
  signal,
  effect,
  input,
  Output,
  EventEmitter,
} from '@angular/core';
import { SubSink } from 'subsink';
import { ActivatedRoute, Router } from '@angular/router';
import {
  Maybe,
  GetMediaForMediaManagePageQuery,
  GetMediaForMediaManagePageGQL,
  ResourceType,
  UpdateMediaSubscriptionGQL,
  Media,
} from '@designage/gql';
import {
  EncryptionService,
  ToasterService,
  RoutingStateService,
  SlidePanelService,
} from '@desquare/services';
import { ApolloError } from '@apollo/client/errors';
import { environment } from '@desquare/environments';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { LoaderComponent } from '@desquare/components/common/src/loader/loader.component';
import { CloudinaryMediaComponent } from '@desquare/components/common/src/cloudinary/cloudinaryMedia.component';
import { MediaSettingsFormComponent } from '../media-settings-form/media-settings-form.component';
import { MediaMetaDataComponent } from '../media-meta-data/media-meta-data.component';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { MediaStore } from '@desquare/stores';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

enum Tabs {
  SETTINGS,
  DETAILS,
}
@Component({
  standalone: true,
  imports: [
    FormsModule,
    TranslateModule,
    CloudinaryMediaComponent,
    MediaSettingsFormComponent,
    MediaMetaDataComponent,
    NgbTooltip,
  ],
  selector: 'app-media-manage',
  templateUrl: './media-manage.component.html',
  styleUrls: ['./media-manage.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MediaManageComponent {
  mediaStore = inject(MediaStore);
  slidePanelService = inject(SlidePanelService);
  destroyRef = inject(DestroyRef);

  @Output() mediaDeleted = new EventEmitter();

  media = input.required<Media>();

  currentTab = signal<Tabs>(Tabs.SETTINGS);
  loaderMessage!: string;
  loading = signal(true);
  tabs = Tabs;
  mediaId = signal<string>('');
  downloadLink = computed(() => this.media().secureUrl);
  publicId = computed(() => this.media().publicId);
  isImage = computed(() => this.media().type === ResourceType.Image);
  isVideo = computed(() => this.media().type === ResourceType.Video);
  isRaw = computed(() => this.media().type === ResourceType.Raw);

  updateMediaSubscriptionSignal = toSignal(
    inject(UpdateMediaSubscriptionGQL).subscribe(),
  );

  title = computed(() => {
    if (!this.media().metadata) {
      return 'METADATA_MISSING_ERROR';
    } else {
      return this.media().name;
    }
  });

  constructor(private updateMediaSubscription: UpdateMediaSubscriptionGQL) {
    // effect(() => {
    //   console.log('mediaInput', this.media());
    //   console.log(
    //     'updateMediaSubscriptionSignal',
    //     this.updateMediaSubscriptionSignal()
    //   );
    // });
  }
  mediaUrl = computed(() => {
    if (!this.media().metadata) {
      return environment.assets.missingImagePlaceholder;
    } else {
      return environment.assets.placeholderImage;
    }
  });

  get isMetadataAvailable() {
    return this.media().metadata;
  }

  initMedia(mediaId: string) {
    this.loaderMessage = 'FETCHING_MEDIA';

    this.updateMediaSubscription
      .subscribe({ id: mediaId })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(({ data }) => {
        if (data?.updateMedia?.media) {
          console.log('updateMedia', data);
          const oldMedia = this.media();
          const newMedia = data.updateMedia.media;
          if (!newMedia.metadata) {
            newMedia.metadata = oldMedia?.metadata;
          }
        }
      });
  }
}
