<div class="pt-3 container-fluid content-container-card manage-media-container">
  <div class="row">
    <div
      class="text-truncate d-flex align-items-center justify-content-between sliding-panel-header"
    >
      <label class="d-inline pointer-text">
        {{ media().name || ('NO_DATA' | translate) }}
      </label>
    </div>
  </div>
  <div class="card-box p-0">
    <div class="media-manage-grid">
      <div class="text-center">
        @if (isVideo()) {
          <div class="mt-3">
            <cl-media
              mediaType="video"
              [public-id]="publicId()"
              [ngbTooltip]="title() | translate"
              class="cl-media-video"
            ></cl-media>
          </div>
        }
        @if (isRaw()) {
          <img
            [src]="mediaUrl()"
            [alt]="'IMAGE' | translate"
            class="img-fluid screenshot mh-60 p-2"
            [ngbTooltip]="title() | translate"
          />
        }
        @if (isImage()) {
          <cl-media
            mediaType="image"
            [public-id]="publicId()"
            [ngbTooltip]="title() | translate"
            class="img-fluid screenshot mh-60 p-2 cl-media-image"
          ></cl-media>
        }
      </div>
      <div class="p-3">
        <div class="form-group">
          <ul class="nav nav-pills navtab-bg">
            <li class="nav-item">
              <a
                (click)="currentTab.set(tabs.SETTINGS)"
                [class.active]="currentTab() === tabs.SETTINGS"
                data-toggle="tab"
                aria-expanded="true"
                class="nav-link"
              >
                <i class="mdi mdi-settings-outline me-1"></i>
                {{ 'SETTINGS' | translate }}
              </a>
            </li>
            <li class="nav-item">
              <a
                (click)="currentTab.set(tabs.DETAILS)"
                [class.active]="currentTab() === tabs.DETAILS"
                data-toggle="tab"
                aria-expanded="true"
                class="nav-link"
              >
                <i class="mdi mdi-settings-outline me-1"></i>
                {{ 'DETAILS' | translate }}
              </a>
            </li>
          </ul>
        </div>
        <div class="col-12">
          @if (currentTab() === tabs.SETTINGS) {
            <app-media-settings-form
              [media]="media()"
              (mediaDeleted)="mediaDeleted.emit()"
              [downloadLink]="downloadLink()"
            />
          }
          @if (currentTab() === tabs.DETAILS) {
            <app-media-meta-data [details]="media().metadata" />
          }
        </div>
      </div>
    </div>
  </div>
</div>
