import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  Maybe,
  User,
  InviteUserGQL,
  GetProfileDetailedGQL,
  RemoveProfileUserGQL,
  ProfileDetailedFragment,
} from '@designage/gql';
import {
  CurrentUserService,
  EncryptionService,
  SessionService,
  ToasterService,
} from '@desquare/services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RemoveUserDialogComponent } from '../remove-user-dialog/remove-user-dialog.component';
import { SubSink } from 'subsink';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ApolloError } from '@apollo/client/errors';
import { InviteUserDialogComponent } from '@designage/app/shared/invite-user-dialog/invite-user-dialog.component';
import {
  IDatatableRowActivateArgs,
  IInviteUserForm,
} from '@desquare/interfaces';
import { DatatableRowActivationType } from '@desquare/enums';
import { Router } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { LoaderComponent } from '@desquare/components/common/src/loader/loader.component';
import { UserSelectionComponent } from '@designage/app/shared/user-selection/user-selection.component';
import { CopyToClipboardComponent } from '@desquare/components/common/src/copy-to-clipboard/copy-to-clipboard.component';

@Component({
  standalone: true,
  imports: [
    FormsModule,
    TranslateModule,
    LoaderComponent,
    UserSelectionComponent,
    CopyToClipboardComponent,
  ],
  selector: 'app-profile-users',
  templateUrl: './profile-users.component.html',
  styleUrls: ['./profile-users.component.scss'],
})
export class ProfileUsersComponent implements OnInit, OnDestroy {
  private subs = new SubSink();
  private _selectedUsers: User[] = [];

  get selectedUsers(): User[] {
    return this._selectedUsers;
  }

  set selectedUsers(users: User[]) {
    this._selectedUsers = users;
    // this.sortUserSelection();
  }

  // TODO: create a better UI
  /** when inviting a user this will contain the invite url */
  lastInvitedUrl: Maybe<string> = '';

  profileUsersUpdated = false;
  defaultProfileText!: string;
  profile!: Maybe<ProfileDetailedFragment>;
  loading = false;
  loaderMessage!: string;

  constructor(
    private router: Router,
    private toasterService: ToasterService,
    private modalService: NgbModal,
    private translateService: TranslateService,
    private inviteUserGQL: InviteUserGQL,
    private getProfileGQL: GetProfileDetailedGQL,
    private session: SessionService,
    private removeProfileUserGQL: RemoveProfileUserGQL,
    private encryptionService: EncryptionService,
    private currentUserService: CurrentUserService,
  ) {}

  ngOnInit() {
    this.initVariables();
    this.initSubscriptions();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  initSubscriptions() {
    const profileId = this.session.profileId();
    if (profileId) {
      this.initProfile(profileId);
    }
  }

  async initProfile(id: string) {
    this.profile = null;
    this.subs.sink = this.getProfileGQL
      .watch({ id })
      .valueChanges.subscribe(({ loading, data: { profile } }) => {
        this.loading = loading;
        if (this.loading) {
          this.loaderMessage = 'FETCHING_PROFILE';
        }
        this.profile = profile;
        this.selectedUsers = this.profile?.users as User[];

        this.loading = false;
      });
  }

  initVariables() {
    this.subs.sink = this.translateService
      .get('THIS_PROFILE')
      .subscribe((value) => {
        this.defaultProfileText = value;
      });
  }

  sortUserSelection() {
    this.selectedUsers.sort((x, y) => {
      if (this.profile && this.profile.owner) {
        return x.id === this.profile.owner.id
          ? -1
          : y.id === this.profile.owner.id
            ? 1
            : 0;
      } else {
        return 0;
      }
    });
  }

  openInviteDialog() {
    const modalReference = this.modalService.open(InviteUserDialogComponent, {
      backdrop: 'static',
    });

    modalReference.result.then(
      (values: IInviteUserForm) => {
        this.inviteUser(values);
      },
      () => {},
    );
  }

  inviteUser({ email, roleId }: IInviteUserForm) {
    if (this.profile && this.profile.id) {
      this.loading = true;
      this.loaderMessage = 'SENDING_INVITATION';

      this.lastInvitedUrl = '';
      this.subs.sink = this.inviteUserGQL
        .mutate({
          input: {
            email,
            roleIds: [roleId],
            profileId: this.profile.id,
          },
        })
        .subscribe({
          next: ({ data }) => {
            if (data && data.inviteUser.isSuccessful) {
              this.lastInvitedUrl = data.inviteUser.url;
              this.toasterService.success('INVITATION_SENT');
            } else {
              this.toasterService.error('UNKNOWN_ERROR');
            }

            this.loading = false;
          },
          error: (error: ApolloError) => {
            error.graphQLErrors.forEach((gqlError) => {
              console.error('getLocationChannelsGQL error: ', gqlError);
              this.toasterService.handleGqlError(gqlError);
            });
            this.loading = false;
          },
        });
    }
  }

  onUserClick(args: IDatatableRowActivateArgs) {
    console.log('onUserClick: ', args);
    this.navigateToChannelOld(args);
  }

  navigateToUser(id: string) {
    const enctryptedId = this.encryptionService.encrypt(id);
    if (this.currentUserService.canManageUsers) {
      this.router.navigate(['/user/manage', enctryptedId]); // , profileId
    } else {
      this.toasterService.error('ERROR_USER_NO_PERMISSION');
    }
  }

  navigateToChannelOld({ type, row }: IDatatableRowActivateArgs) {
    if (type === DatatableRowActivationType.CLICK) {
      const user: User = row;
      if (user?.id && this.profile?.id) {
        const id = this.encryptionService.encrypt(user.id);
        // const profileId = this.encryptionService.encrypt(this.profile.id);

        if (this.currentUserService.canManageUsers) {
          this.router.navigate(['/user/manage', id]); // , profileId
        } else {
          this.toasterService.error('ERROR_USER_NO_PERMISSION');
        }
      } else {
        this.toasterService.error('UNKNOWN_ERROR');
      }
    }
  }

  onUserDeselected(user: User) {
    this.selectedUsers = [user, ...this.selectedUsers];
    if (this.profile?.owner && user.id === this.profile.owner.id) {
      this.toasterService.error('CANNOT_DESELECT_OWNER');
    } else {
      const modalReference = this.showRemoveUserDialog(user.displayName);

      modalReference.result.then(
        () => {
          this.removeUser(user.id);
          const index = this.selectedUsers.findIndex((x) => x.id === user.id);
          this.selectedUsers.splice(index, 1);
        },
        () => {},
      );
    }
  }

  removeUser(userId: string) {
    if (!this.profile?.id) return;

    this.profileUsersUpdated = true;

    this.subs.sink = this.removeProfileUserGQL
      .mutate({
        input: {
          profileId: this.profile.id,
          userId: userId,
        },
      })
      .subscribe({
        next: ({ data }) => {
          if (data?.removeProfileUser.isSuccessful) {
            this.toasterService.success('UPDATE_PROFILE_SUCCESS');
          } else {
            this.toasterService.error('UNKNOWN_ERROR');
          }
          this.profileUsersUpdated = false;
        },
        error: (error: ApolloError) => {
          error.graphQLErrors.forEach((gqlError) => {
            console.error('removeProfileUser', gqlError);
            this.toasterService.handleGqlError(gqlError);
          });

          this.profileUsersUpdated = false;
        },
      });
  }

  showRemoveUserDialog(userDisplayName?: string | null) {
    const modalReference = this.modalService.open(RemoveUserDialogComponent);
    modalReference.componentInstance.user = userDisplayName;
    modalReference.componentInstance.profile = this.profile
      ? this.profile.name
      : this.defaultProfileText;

    return modalReference;
  }
}
