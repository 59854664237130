import { Component, Input, OnInit, inject } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { Layout } from '@designage/gql';
import { IDesignageDataTableColumns } from '@desquare/interfaces';
import {
  EncryptionService,
  SlidePanelService,
  ToasterService,
} from '@desquare/services';
import { NgIf } from '@angular/common';
import { DesignageDataTableComponent } from '@desquare/components/common/src/designage-data-table/designage-data-table.component';

@Component({
  standalone: true,
  imports: [DesignageDataTableComponent, NgIf, RouterOutlet],
  selector: 'app-layout-list',
  templateUrl: './layout-list.component.html',
  styleUrls: ['./layout-list.component.scss'],
})
export class LayoutListComponent implements OnInit {
  @Input() layouts: Layout[] = [];
  @Input() enableSlidePanel = true;
  slidePanelService = inject(SlidePanelService);
  isSlidePanelOpen = this.slidePanelService.getIsPanelOpen();
  selectedLayoutaId = this.slidePanelService.getPanelComponentId();

  desColumns!: IDesignageDataTableColumns[];
  pageSize!: number;
  page!: number;

  constructor(
    private toasterService: ToasterService,
    private encryptionService: EncryptionService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.desColumns = [
      {
        fieldName: 'name',
        name: 'Name',
        type: 'string',
        visible: 'mandatory',
        flex: '1',
      },
      {
        fieldName: 'description',
        name: 'Description',
        type: 'string',
        visible: true,
        flex: '1',
      },
    ];
  }

  onRowClick(e: unknown) {
    const layout = e as Layout;
    // guard statements
    if (!layout || !layout.id)
      return this.toasterService.error('UNKNOWN_ERROR');
    // TODO HERE: create guard for managing layouts
    // if (!this.currentUserService.canManageLayout) return
    this.slidePanelService.setPanelComponentId(layout.id);
    // navigate to manage layout page
    const encryptedId = this.encryptionService.encrypt(layout.id);
    this.router.navigate(['layouts/manage', encryptedId]);
  }
}
