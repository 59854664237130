import {
  Component,
  ChangeDetectionStrategy,
  input,
  TemplateRef,
  inject,
  NgZone,
} from '@angular/core';
import { environment } from '@desquare/environments';
import { IScreenshotContext } from '@desquare/interfaces';
import {
  NgbModal,
  NgbNavModule,
  NgbPopoverModule,
  NgbTooltip,
} from '@ng-bootstrap/ng-bootstrap';
import { DeviceDataService } from '@desquare/services';
import { DeviceData } from '@desquare/types';
import { DeviceCommandsComponent } from '@desquare/components/common/src/device-commands/device-commands.component';
import { DeviceStatusInfo } from '@desquare/models';
import { NgClass } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { DateProxyPipe } from '@desquare/components/common/src/pipe/pipe/date-proxy.pipe';
import { IDeviceCommandsComponentOptions } from '@desquare/components/common/src/device-commands/device-commands.model';

@Component({
  standalone: true,
  imports: [
    NgClass,
    TranslateModule,
    NgbTooltip,
    NgbNavModule,
    NgbPopoverModule,
    DeviceCommandsComponent,
  ],
  selector: 'app-device-screenshot',
  template: `<div class="h-100 pb-5 d-flex flex-column">
      @if (device().screenshotUrl) {
        <img
          [src]="device().screenshotUrl"
          [alt]="'DEVICE_SCREENSHOT' | translate"
        />
      } @else {
        <img
          [src]="missingScreenshotPlaceholder"
          [alt]="'DEVICE_SCREENSHOT' | translate"
        />
      }
      <div class="d-flex mt-2 align-items-center justify-content-center">
        <div
          class="fs-4 px-2 pointer"
          ngbTooltip="{{ 'RELOAD_SCREENSHOT' | translate }}"
          (click)="updateScreenshot()"
        >
          <i
            [ngClass]="
              loader
                ? 'spinner-border spinner-border-sm mx-1'
                : 'ri-camera-switch-fill'
            "
          ></i>
        </div>
        <div
          class="fs-4 px-2 pointer"
          ngbTooltip="{{ 'ENLARGE' | translate }}"
          (click)="openImageDialog(screenShotModalTemplate)"
        >
          <i class="ri-zoom-in-fill"></i>
        </div>
        <div
          class="fs-5 px-2 pointer"
          [ngbPopover]="popDeviceCommands"
          animated="true"
          container="body"
          [autoClose]="'outer'"
          placement="bottom auto"
        >
          <button class="btn btn-sm btn-light">
            {{ 'DEVICE_ACTIONS' | translate }}
          </button>
        </div>
      </div>
    </div>

    <ng-template #popDeviceCommands>
      <app-device-commands
        [device]="device()"
        layout="expanded"
        [options]="deviceCommandsOptions()"
        [channelId]="device().channelId ?? ''"
      />
    </ng-template>
    <ng-template #screenShotModalTemplate>
      <img
        #image
        [src]="device().screenshotUrl"
        [alt]="'DEVICE_SCREENSHOT' | translate"
        class="img-fluid"
        style="display: block; margin: 0 auto"
      />
    </ng-template> `,
  styleUrls: ['./device-screenshot.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeviceScreenshotComponent {
  device = input.required<DeviceData>();
  modalService = inject(NgbModal);
  deviceDataService = inject(DeviceDataService);
  ngZone = inject(NgZone);

  missingScreenshotPlaceholder =
    environment.assets.missingScreenshotPlaceholder;

  placeHolderImage!: string;
  loader = false;

  useDefaultScreenshot = false;

  /** a new screenshot via mqtt */
  updateScreenshot() {
    this.loader = true;
    this.deviceDataService.askForScreenshot(this.device().id);
    this.ngZone.runOutsideAngular(() => {
      setTimeout(() => {
        this.loader = false;
      }, 50);
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  openImageDialog(template: TemplateRef<HTMLElement>) {
    this.modalService.open(template, {
      size: 'lg',
      windowClass: 'custom-centered-modal',
    });
  }

  deviceCommandsOptions(): IDeviceCommandsComponentOptions {
    return {
      controls: {
        reboot: {
          disable: this.device().status === DeviceStatusInfo.Offline,
        },
        clearCache: {
          disable: this.device().status === DeviceStatusInfo.Offline,
        },
        updateApplet: {
          disable:
            this.device().signageOSDeviceTiming?.appletVersion ===
            this.device().signageOSDeviceTiming?.activeAppletVersion,
        },
        turnOnScreen: {
          disable: this.device().status === DeviceStatusInfo.Online,
        },
        turnOffScreen: {
          disable: this.device().status === DeviceStatusInfo.Offline,
        },
        refreshInfo: {
          disable: this.device().status === DeviceStatusInfo.Offline,
        },
      },
    };
  }
}
