import { Component, computed, inject, signal } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocationManageComponent } from '../location-manage/location-manage.component';
import { SlidePanelComponent } from '../../shared/slide-panel/slide-panel.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { EncryptionService, SlidePanelService } from '@desquare/services';
import { LocationsStore } from '@desquare/stores';

@Component({
  standalone: true,
  imports: [SlidePanelComponent, LocationManageComponent],
  selector: 'app-location-manage-slider',
  template: `<app-slide-panel
    (closePanel)="closeLocationManagePanel()"
    [fullscreenTooltip]="'TOGGLE_CHANNEL_TABLE'"
    [closePanelTooltip]="'CLOSE_THIS_CHANNEL'"
    [fullscreen]="true"
    [openWidth]="20"
    [panelContent]="'locationManage'"
  >
    @if (location()) {
      <app-location-manage
        [location]="location()!"
        (closeSlidingPanel)="closeLocationManagePanel()"
      />
    }
  </app-slide-panel> `,
})
export class LocationManageSliderComponent {
  route = inject(ActivatedRoute);
  router = inject(Router);
  encryptionService = inject(EncryptionService);
  locationssStore = inject(LocationsStore);
  params$ = this.route.params.pipe(takeUntilDestroyed());
  locationId = signal<string>('');
  constructor() {
    this.params$.subscribe((params) => {
      const { id } = params;
      this.locationId.set(this.encryptionService.decrypt(id));
    });
    inject(SlidePanelService).setPanelComponentId(this.locationId());
  }
  location = computed(() =>
    this.locationssStore.locations().find((x) => x.id === this.locationId()),
  );

  closeLocationManagePanel() {
    this.router.navigate(['../../../'], { relativeTo: this.route });
  }
}
