import {
  Component,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
  TemplateRef,
  inject,
} from '@angular/core';
import { SubSink } from 'subsink';
import {
  User,
  Maybe,
  ProfileUsersForProfileUserListGQL,
  RemoveProfileUserGQL,
  UsersForUserListFragment,
  CustomFeatures,
} from '@designage/gql';
import { IDesignageDataTableColumns } from '@desquare/interfaces';
import {
  CurrentUserService,
  EncryptionService,
  PopupService,
  SlidePanelService,
  ToasterService,
} from '@desquare/services';
import { lastValueFrom } from 'rxjs';
import { ConfirmationResponse, Roles } from '@desquare/enums';
import { TranslateModule } from '@ngx-translate/core';
import { DesignageDataTableComponent } from '@desquare/components/common/src/designage-data-table/designage-data-table.component';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';

@Component({
  standalone: true,
  imports: [RouterOutlet, TranslateModule, DesignageDataTableComponent],
  selector: 'app-user-selection',
  templateUrl: './user-selection.component.html',
  styleUrls: ['./user-selection.component.scss'],
})
export class UserSelectionComponent implements OnInit, OnDestroy, OnChanges {
  @Input() profileId!: Maybe<string>;
  @Input() selectActionButtonText = 'SELECT';
  @Input() deselectActionButtonText = 'DESELECT';
  @Input() enableSelectionActionButtons = false;
  @Input() profileUsersUpdated = false;
  @Input() enableSlidePanel = true;
  @Input()
  get selectedUsers() {
    return this.selectedUsersValue;
  }

  private slidePanelService = inject(SlidePanelService);
  private encryptionService = inject(EncryptionService);
  private router = inject(Router);
  private route = inject(ActivatedRoute);
  private toasterService = inject(ToasterService);

  set selectedUsers(users: User[]) {
    this.selectedUsersValue = users;
    this.selectedUsersChange.emit(this.selectedUsersValue);
  }

  @Output() selectedUsersChange = new EventEmitter<User[]>();
  @Output() rowActivate = new EventEmitter<string>();
  @Output() userSelect = new EventEmitter<User>();
  @Output() userDeselect = new EventEmitter<User>();
  @Output() openInviteDialogClicked = new EventEmitter<void>();

  private selectedUsersValue: User[] = [];
  private subs = new SubSink();

  users: UsersForUserListFragment[] = [];

  page = 1;
  pageSize = 10;
  total = 0;
  loading = false;

  @ViewChild('removeUserModal') removeUserModal!: TemplateRef<any>;

  onRemoveUserButtonClick = (user: UsersForUserListFragment): void => {
    this.removeUserFromProfile(user);
  };

  /** MFA feature is activated on profile */
  mfaEnabledOnProfile = false;
  mfaInfo = (user: UsersForUserListFragment): string => {
    if (user) {
      if (user.auth0Profile?.user_metadata?.reset_mfa) {
        return 'MFA_RESET';
      }
      if ((user.auth0Profile?.multifactor || []).length > 0) {
        return 'MFA_ACTIVE';
      }
      // if (user.auth0Profile?.user_metadata?.use_mfa) {\
      if (this.mfaEnabledOnProfile) {
        return 'MFA_INACTIVE';
      }
    }
    return 'MFA_NOT_LICENSED';
  };

  desColumns: IDesignageDataTableColumns[] = [];
  initialLoad = true;

  constructor(
    private getProfileUsersGQL: ProfileUsersForProfileUserListGQL,
    private removeProfileUserGQL: RemoveProfileUserGQL,
    private currentUserService: CurrentUserService,
    private popupService: PopupService,
  ) {}

  isSlidePanelOpen = this.slidePanelService.getIsPanelOpen();
  selectedUserId = this.slidePanelService.getPanelComponentId();

  ngOnInit() {
    this.initColumns();
    this.mfaEnabledOnProfile = this.currentUserService.isFeatureEnabled(
      CustomFeatures.UserMfa,
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.profileId) {
      this.getUsers();
    }
    if (changes.profileUsersUpdated) {
      this.getUsers();
    }
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  initColumns() {
    this.desColumns = [
      {
        name: 'name',
        fieldName: 'displayName',
        type: 'string',
        visible: 'mandatory',
        flex: '2',
      },
      {
        name: 'email',
        fieldName: 'auth0Profile.email',
        type: 'string',
        visible: 'mandatory',
      },
    ];

    // TODO - check if MFA is activated on the current profile
    this.desColumns.push({
      name: 'MFA Security',
      fieldName: 'mfaSecurity',
      type: 'template',
      templateRef: 'mfaSecurity',
      visible: 'mandatory',
      disableSorting: true,
    });

    if (
      this.currentUserService.isSuperAdmin ||
      this.currentUserService.isReseller ||
      this.currentUserService.isProfileManager
    ) {
      this.desColumns.push(
        {
          name: 'role',
          fieldName: 'role.name',
          type: 'string',
          visible: 'mandatory',
        },
        {
          name: 'Remove User',
          fieldName: 'removeUser',
          type: 'template',
          templateRef: 'removeButton',
          visible: 'mandatory',
          disableSorting: true,
        },
      );
    }
    // {
    //   name: 'active',
    //   fieldName: 'active',
    //   type: 'boolean',
    //   visible: true,
    // },
  }

  getUsers() {
    if (this.profileId) {
      this.loading = true;
      this.subs.sink = this.getProfileUsersGQL
        .watch({ profileId: this.profileId }, { fetchPolicy: 'network-only' })
        .valueChanges.subscribe(({ data, loading }) => {
          if (data.profile) {
            this.users = data.profile.users;
            if (!this.currentUserService.isSuperAdmin) {
              this.users = this.users.filter(
                (user) => user.role?.name !== Roles.SUPER_ADMIN,
              );
            }
            if (!this.currentUserService.isReseller) {
              this.users = this.users.filter(
                (user) => user.role?.name !== Roles.RESELLER,
              );
            }
            this.total = this.users.length;
          } else {
            this.users = [];
            this.total = 0;
          }
          this.loading = loading;
          this.profileUsersUpdated = false;
        });
    }
  }

  onRowClick(event: unknown) {
    const user = event as User;
    console.log('onRowClick: ', user);
    // this.rowActivate.emit(user.id);
    this.slidePanelService.setPanelComponentId(user.id);
    this.navigateToUser(user);
  }
  navigateToUser(user: User) {
    if (user?.id && this.profileId) {
      console.log('navigateToUser: ', user.displayName);

      const id = this.encryptionService.encrypt(user.id);
      const profileId = this.encryptionService.encrypt(this.profileId);

      if (this.currentUserService.canManageUsers && profileId) {
        console.log('router navigate', id);
        console.log('user', user);

        this.router.navigate(['manage', id], {
          relativeTo: this.route,
        });
      } else {
        this.toasterService.error('ERROR_USER_NO_PERMISSION');
      }
    } else {
      this.toasterService.error('UNKNOWN_ERROR');
    }
  }

  selectUser(user: User) {
    this.userSelect.emit(user);
  }

  deselectUser(user: User) {
    this.userDeselect.emit(user);
  }

  openInviteDialog() {
    this.openInviteDialogClicked.emit();
  }

  async removeUserFromProfile(user: UsersForUserListFragment) {
    const confirmation = await this.popupService.confirm(
      'REMOVE_USER_CONFIRM',
      user.displayName,
      true,
      'REMOVE_USER',
    );
    if (confirmation === ConfirmationResponse.Yes) {
      await lastValueFrom(
        this.removeProfileUserGQL.mutate({
          input: { userId: user.id, profileId: this.profileId! },
        }),
      );
      this.getUsers();
    }
  }
}
