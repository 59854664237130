import {
  Component,
  inject,
  input,
  computed,
  effect,
  ChangeDetectionStrategy,
  output,
} from '@angular/core';
import { Location } from '@designage/gql';
import { CurrentUserService } from '@desquare/services';
import { LoaderComponent } from '@desquare/components/common/src/loader/loader.component';
import { LocationsStore } from '@desquare/stores';
import { ILocationForm, ILocation } from '@desquare/interfaces';
import { LocationFormComponent } from '../location-form/location-form.component';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { DeviceListComponent } from '@designage/app/device/device-list/device-list.component';
import { Point } from 'mapbox-gl';

enum Tabs {
  SETTINGS,
  DETAILS,
}
@Component({
  standalone: true,
  imports: [
    LoaderComponent,
    LocationFormComponent,
    DeviceListComponent,
    NgbNavModule,
    TranslateModule,
  ],
  selector: 'app-location-manage',
  template: `@if (loading()) {
      <app-loader />
    } @else {
      <div class="container-fluid content-container-card">
        <div
          class="px-2 text-truncate d-flex align-items-center justify-content-between sliding-panel-header "
        >
          <label class="d-inline pointer-text">{{ location().name }}</label>
        </div>
        <nav ngbNav #nav="ngbNav" class="mt-4 nav-tabs">
          <ng-container ngbNavItem>
            <a ngbNavLink>{{ 'DETAILS' | translate }}</a>
            <ng-template ngbNavContent>
              <app-location-form
                [location]="locationForm()"
                (closeSlidingPanel)="closeSlidingPanel.emit()"
              />
            </ng-template>
          </ng-container>
          <ng-container ngbNavItem>
            <a ngbNavLink>{{
              'DEVICES_CONNECTED_TO_THIS_LOCATION' | translate
            }}</a>
            <ng-template ngbNavContent>
              <h4 class="py-2">
                {{ 'DEVICES_CONNECTED_TO_THIS_LOCATION' | translate }}
              </h4>
              <app-device-list
                [buttonsHidden]="false"
                [locationId]="location().id!"
              />
            </ng-template>
          </ng-container>
        </nav>
        <div [ngbNavOutlet]="nav"></div>
      </div>
    } `,
  styleUrls: ['./location-manage.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocationManageComponent {
  locationsStore = inject(LocationsStore);
  loading = this.locationsStore.loading;
  location = input.required<ILocation>();

  closeSlidingPanel = output();

  loaderMessage!: string;
  locationForm = computed((): ILocationForm => {
    return {
      id: this.location().id,
      name: this.location().name ?? '',
      streetAddress1: this.location().streetAddress1 ?? '',
      streetAddress2: this.location().streetAddress2 ?? '',
      city: this.location().city ?? '',
      zip: this.location().zip ?? '',
      country: this.location().country ?? '',
      phoneNumber: this.location().phoneNumber ?? '',
      region: this.location().region ?? '',
      coordinates: new Point(
        this.location().coordinates?.x ?? 0,
        this.location().coordinates?.y ?? 0,
      ),
    };
  });
}
