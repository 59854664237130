import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  IDesignageDataTableColumns,
  ITableRowSelection,
} from '@desquare/interfaces';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormDialogComponent } from '../modals/form-dialog.component';
import { TranslateModule } from '@ngx-translate/core';
import { DesignageDataTableComponent } from '../designage-data-table/designage-data-table.component';

@Component({
  standalone: true,
  imports: [TranslateModule, FormDialogComponent, DesignageDataTableComponent],
  selector: 'table-row-selection',
  template: `<app-form-dialog
    closeButtonText="{{ okText | translate }}"
    dismissButtonText="{{ cancelText | translate }}"
    headerText="{{ title | translate }}"
    [valid]="selectedRows.length > 0"
    [values]="selectedRows"
  >
    <designage-data-table
      [configId]="tableConfigId"
      [data]="rows"
      [columns]="columns"
      [(selectedRows)]="selectedRows"
      [alwaysSort]="true"
      (rowClick)="onRowClick($event)"
      [showMultiSelect]="multiSelect"
    >
    </designage-data-table>
  </app-form-dialog> `,
})
export class TableRowSelectionDialogComponent implements ITableRowSelection {
  @Input() title = 'select a row';
  @Input() cancelText = 'CANCEL';
  @Input() okText = 'OK';
  @Input() tableConfigId = 'table-row-selection';
  @Input() columns: IDesignageDataTableColumns[] = [
    /* {
      fieldName: 'status',
      name: '',
      type: 'status-indicator',
      visible: 'mandatory',
    },*/
    {
      fieldName: 'name',
      name: 'CHANNEL_NAME',
      type: 'string',
      visible: 'mandatory',
    },
    /* {
      fieldName: 'layout.name',
      name: 'LAYOUT',
      type: 'string',
      visible: true,
      flex: false,
    },
    {
      fieldName: 'devices',
      name: 'DEVICES',
      type: 'generic-array',
      visible: true,
      disableSorting: true,
      arrayHeader: 'DEVICES_CONNECTED_TO_CHANNEL',
      columns: [
        {
          fieldName: 'id',
          name: '',
          type: 'device-status-indicator',
          visible: 'mandatory',
        },
        {
          fieldName: 'name',
          name: 'DEVICE_NAME',
          type: 'string',
          visible: 'mandatory',
        },
        {
          fieldName: 'location.name',
          name: 'LOCATION',
          type: 'object',
          visible: 'mandatory',
        },
        {
          fieldName: 'appVersionStatus',
          name: 'Screen App',
          type: 'string',
          visible: 'mandatory',
        },
      ],
    },*/
    {
      fieldName: 'playlists',
      name: 'PLAYLISTS',
      type: 'generic-array',
      visible: true,
      disableSorting: true,
      arrayHeader: 'PLAYLISTS_CONNECTED_TO_THIS_CHANNEL',
      columns: [
        {
          fieldName: 'name',
          name: 'PLAYLIST_NAME',
          type: 'string',
          visible: 'mandatory',
        },
      ],
    },
    // {
    //   fieldName: 'screenStatus',
    //   name: 'Screen',
    //   type: 'boolean',
    //   visible: true,
    // },
  ];
  @Input() multiSelect = false;
  @Input() rows: any[] = [];

  public selectedRows: any[] = [];

  get hasNoData() {
    return (this.rows?.length || 0) > 0;
  }

  constructor(private modal: NgbActiveModal) {}

  onRowClick(event: any) {
    this.modal.close([event]);
  }
}
