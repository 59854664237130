import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  OnChanges,
  SimpleChanges,
  ViewChild,
  ElementRef,
} from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormGroupDirective,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  ToasterService,
  CurrentUserService,
  RoutingStateService,
} from '@desquare/services';
import {
  UpdateUserGQL,
  User,
  Maybe,
  Role,
  RolesGQL,
  DeactivateUserGQL,
  ChangePasswordGQL,
  MeGQL,
  MeQuery,
  ChangeUserMfaGQL,
  CustomFeatures,
} from '@designage/gql';
import { IUser } from '@desquare/interfaces';
import { SubSink } from 'subsink';
import { Roles } from '@desquare/enums';
import { ApolloError } from '@apollo/client/errors';
import { NgbDropdownModule, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TransferRoleDialogComponent } from '../transfer-role-dialog/transfer-role-dialog.component';
import { UserDeactivateDialogComponent } from '../user-deactivate-dialog/user-deactivate-dialog.component';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { userActions } from '@desquare/store/actions';
import { AuthService } from '@auth0/auth0-angular';
import { authUtil } from '@desquare/utils';
import { environment } from '@desquare/environments';
import { lastValueFrom, map } from 'rxjs';
import { set } from 'lodash';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { DateProxyPipe } from '@desquare/components/common/src/pipe/pipe/date-proxy.pipe';

interface IEditUserForm {
  name: string;
  roleId: string;
}

@Component({
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    NgbDropdownModule,
    DateProxyPipe,
  ],
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.scss'],
})
export class UserSettingsComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild('userNameInput') userNameInput!: ElementRef;

  private subs = new SubSink();

  @Input() user!: Maybe<IUser>;
  @Output() loading = new EventEmitter<boolean>();
  @Output() updatedUser = new EventEmitter<User>();
  @Output() loaderMessage = new EventEmitter<string>();

  userForm!: Maybe<FormGroup>;
  roles: Role[] = [];
  // Commented out for now. Might be used if user can be assigned with multiple roles.
  // newRoles: string[] = [];
  // oldRoles: string[] = [];
  deactivated!: boolean;
  onErrorMessage!: string;
  hasChanges = false;
  defaultOwner?: Maybe<User>;
  isFormValid = false;
  isFormPristine = false;
  roleLoading = false;
  updatingUser = false;
  sendingChangePasswordRequest = false;
  deactivatingUser = false;
  currentUser!: Maybe<MeQuery['me']>;
  isEditingName = false;
  isEditingRole = false;

  get canManageUsers() {
    return this.currentUserService.canManageUsers;
  }

  /** user has enrolled MFA */
  get isMFAActivated() {
    return (this.user?.auth0Profile?.multifactor?.length || 0) > 0;
  }
  /** user can enroll MFA */
  get isMFAEnabled() {
    return this.user?.auth0Profile?.user_metadata?.use_mfa || false;
  }

  constructor(
    private formBuilder: FormBuilder,
    private toasterService: ToasterService,
    private updateUserGQL: UpdateUserGQL,
    private currentUserService: CurrentUserService,
    private getAllRolesGQL: RolesGQL,
    private modalService: NgbModal,
    private store: Store,
    private meGQL: MeGQL,
  ) {}

  get saveEnabled() {
    return this.isFormValid && !this.isFormPristine;
  }

  get canShowProfile() {
    return !!(!this.loading && !this.deactivated && this.user && this.user.id);
  }

  get isUserUnavailable() {
    return (this.deactivated || !this.user) && !this.loading;
  }

  async ngOnInit() {
    await this.getRoles();
    this.initForms();

    if (this.user) {
      this.defaultOwner = this.user;
      this.setControlValues(this.user);
    }

    this.initSubscriptions();
    // setTimeout(() => {
    //   console.log('form', this.userForm);
    // }, 2000);
    // this.setFormState(); // WHY IS THIS CALLED???
  }

  initForms() {
    this.userForm = this.formBuilder.group({
      name: [null, Validators.required],
      roleId: [null, Validators.required],
    });
  }

  initSubscriptions() {
    this.subs.sink = this.meGQL
      .watch()
      .valueChanges.subscribe(({ data: { me } }) => {
        this.currentUser = me;
      });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.user && changes.user.currentValue && this.user) {
      this.defaultOwner = this.user;
      this.setControlValues(this.user);
    }
  }

  setControlValues(user: User) {
    if (this.userForm && user) {
      this.userForm.patchValue({
        name: user.displayName,
        roleId:
          user.auth0Profile && user.auth0Profile.roles.length > 0
            ? user.auth0Profile.roles[0].name
            : null,
      });
    }
  }

  emitLoading() {
    this.loading.emit(this.roleLoading || this.updatingUser);
  }

  editNameInput() {
    this.isEditingName = true;
    setTimeout(() => {
      this.userNameInput.nativeElement.select();
    });
  }

  revertChanges() {
    this.userForm?.reset();
    this.setControlValues(this.user!);
  }

  async getRoles() {
    this.roleLoading = true;
    this.loaderMessage.emit('FETCHING_ROLES');
    const result = await lastValueFrom(
      this.getAllRolesGQL.fetch(undefined, {
        fetchPolicy: 'no-cache',
      }),
    );
    this.roles = result.data.roles;
    this.roleLoading = false;
  }

  setFormState() {
    if (this.userForm) {
      this.subs.sink = this.userForm.controls.roleId.valueChanges.subscribe(
        (val) => {
          if (document.activeElement instanceof HTMLElement) {
            document.activeElement.blur();
          }

          this.setNewRole(val);
        },
      );
    }
  }

  setNewRole(roleId: string) {
    if (this.userForm) {
      this.userForm.patchValue({
        roleId, // : this.defaultOwner.auth0Profile.roles[0].id,
      });
    }
    return;
    /*
    if (
      this.defaultOwner &&
      this.defaultOwner.auth0Profile &&
      this.defaultOwner.auth0Profile.roles &&
      this.defaultOwner.auth0Profile.roles.length > 0
    ) {
      const role = this.roles.find((x) => !!x && x.id === roleId);
      if (role && this.defaultOwner.auth0Profile.roles[0].id !== roleId) {
        const modalReference = this.showTransferRoleDialog(role);
        modalReference.result.then(
          () => {},
          () => {
            if (
              this.defaultOwner &&
              this.defaultOwner.auth0Profile &&
              this.defaultOwner.auth0Profile.roles &&
              this.defaultOwner.auth0Profile.roles.length > 0 &&
              this.userForm
            ) {
              this.userForm.patchValue({
                roleId: this.defaultOwner.auth0Profile.roles[0].id,
              });
            }
          }
        );
      }
    }*/
  }

  showTransferRoleDialog(role: Role) {
    const modalReference = this.modalService.open(TransferRoleDialogComponent, {
      backdrop: 'static',
    });
    if (this.user) {
      modalReference.componentInstance.newRoleName = role.name;
      modalReference.componentInstance.displayName = this.user.displayName;
    }

    return modalReference;
  }

  onSubmit(form: IEditUserForm) {
    this.updatingUser = true;
    this.emitLoading();
    this.loaderMessage.emit('UPDATING_USER');

    if (this.user?.auth0Profile && this.user.auth0Profile.user_id) {
      // this.newRoles.push(form.roleId);
      // if (this.user.auth0Profile?.roles[0]?.id) {
      //   this.oldRoles.push(this.user.auth0Profile.roles[0].id);
      // }

      this.subs.sink = this.updateUserGQL
        .mutate({
          input: {
            id: this.user.id || '',
            displayName: form.name,
            newRoleIds: [form.roleId],
            oldRoleIds: this.user.auth0Profile.roles[0].id
              ? [this.user.auth0Profile.roles[0].id]
              : [],
          },
        })
        .subscribe({
          next: ({ data }) => {
            if (data?.updateUser?.isSuccessful) {
              this.toasterService.success('UPDATE_USER_SUCCESSFUL');
              this.user = data.updateUser.user as IUser;

              this.updatedUser.emit(data.updateUser.user as User);
              if (
                this.user &&
                this.currentUserService.currentUser &&
                this.currentUserService.currentUser.id === this.user.id
              ) {
                this.currentUserService.currentUser.displayName = form.name;
                this.store.dispatch(
                  userActions.setActiveUser({ user: this.user }),
                );
              }
            } else {
              // To Do: Handle Error
              this.toasterService.error('UPDATE_PROFILE_ERROR');
            }
          },
          error: (error: ApolloError) => {
            error.graphQLErrors.forEach((gqlError) => {
              console.error('userSettings Submit', gqlError);
              this.toasterService.handleGqlError(gqlError);
            });
          },
          complete: () => {
            this.updatingUser = false;
            this.emitLoading();
          },
        });
    }
  }
}
