<app-form-dialog
  closeButtonText="{{ 'ADD_PLAYLIST' | translate }}"
  dismissButtonText="CANCEL"
  headerText="{{ 'ADD_PLAYLIST' | translate }}"
  [valid]="selectedPlaylists().length > 0"
>
  @if (showLayoutRegions()) {
    <div class="d-flex align-items-top gap-4">
      <div class="d-block">
        <h4 class="ms-3">Choose region:</h4>
        <div ngbDropdown class="mt-3" style="min-width: 20rem">
          <input
            type="button"
            class="form-control form-control-dark dropdown"
            ngbDropdownToggle
            value="{{
              !isDefaultRegion()
                ? selectedRegion()
                : ('REGION_CANVAS_ROOT' | translate)
            }}"
          />
          <div
            ngbDropdownMenu
            class="form-control form-control-dark shadowed-box text-white"
          >
            <span
              ngbDropdownItem
              class="form-control form-control-dark dropdown-items"
              (click)="setRegion('')"
              >{{ 'REGION_CANVAS_ROOT' | translate }}</span
            >
            @for (region of layoutRegions(); track region) {
              <span
                ngbDropdownItem
                class="form-control form-control-dark dropdown-items"
                (click)="setRegion(region ?? '')"
                >{{ region }}</span
              >
            }
          </div>
        </div>
      </div>
      <app-layout-explorer
        [layout]="layout()"
        [canSelect]="true"
        [selectedPreview]="selectedPlaylistPreview()"
        [selected]="selectedRegion()"
        (selectedChange)="setRegion($event)"
      ></app-layout-explorer>
    </div>
  }

  <app-playlist-list
    #playlistList
    (playlistsSelect)="selectedPlaylists.set($event)"
    [showBulkActionControl]="false"
    [showAddPlaylistButton]="false"
    [showHeader]="false"
    [redirectOnSelect]="false"
    [enableCheckbox]="true"
    [enablePlaylistSelect]="false"
    [enableSlidePanel]="false"
  ></app-playlist-list>
</app-form-dialog>
