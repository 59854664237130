import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  inject,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SubSink } from 'subsink';
import {
  NgbAccordionModule,
  NgbDropdownModule,
  NgbModal,
  NgbTooltip,
} from '@ng-bootstrap/ng-bootstrap';
import { IUser } from '@desquare/interfaces';
import {
  ToasterService,
  EncryptionService,
  CurrentUserService,
  RoutingStateService,
  SessionService,
} from '@desquare/services';
import {
  ChangePasswordGQL,
  ChangeUserMfaGQL,
  CustomFeatures,
  DeactivateUserGQL,
  GetUserDetailsGQL,
  Maybe,
  Role,
  UpdateProfileUserRoleGQL,
  UpdateUserGQL,
} from '@designage/gql';
import { environment } from '@desquare/environments';
import { merge, cloneDeep } from 'lodash';
import { ApolloError } from '@apollo/client/errors';
import { authUtil } from '@desquare/utils';
import { UserDeactivateDialogComponent } from '../user-deactivate-dialog/user-deactivate-dialog.component';
import { Store } from '@ngrx/store';
import { userActions } from '@desquare/store/actions';
import { AuthService, LogoutOptions } from '@auth0/auth0-angular';
import { lastValueFrom } from 'rxjs';
import { Roles } from '@desquare/enums';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { UserSettingsComponent } from '../user-settings/user-settings.component';
import { MediaListComponent } from '@designage/app/media/media-list/media-list.component';
import { BreadcrumbService } from 'xng-breadcrumb';
import { LoaderComponent } from '@desquare/components/common/src/loader/loader.component';

enum Tab {
  SECURITY,
  MEDIA,
}

interface IUploadedMediaArgs {
  id: string;
  name: string;
  url: string;
}

@Component({
  standalone: true,
  imports: [
    TranslateModule,
    NgbAccordionModule,
    UserSettingsComponent,
    MediaListComponent,
    NgbTooltip,
    LoaderComponent,
  ],
  selector: 'app-user-manage',
  templateUrl: './user-manage.component.html',
  styleUrls: ['./user-manage.component.scss'],
})
export class UserManageComponent implements OnInit, OnDestroy {
  @ViewChild('userNameInput') userNameInput!: ElementRef;
  private breadcrumbService = inject(BreadcrumbService);

  private subs = new SubSink();

  currentUser!: Maybe<IUser>;
  currentTab!: Tab;
  loaderMessage!: string;
  loading!: boolean;
  loadingProfilePicture = false;
  loadingUserDetails = false;
  loadingContactDetails = false;
  loadingUserSettings = false;
  deactivated!: boolean;
  user?: IUser;
  tabs = Tab;
  profilePicture!: string;
  isEditingName = false;
  sendingChangePasswordRequest = false;
  deactivatingUser = false;

  isMFALicensed = false;

  /** user has enrolled MFA */
  get isMFAActivated() {
    return (this.user?.auth0Profile?.multifactor?.length || 0) > 0;
  }
  /** user can enroll MFA */
  get isMFAEnabled() {
    return this.user?.auth0Profile?.user_metadata?.use_mfa || false;
  }
  /** reset MFA pending */
  get isMFAReset() {
    return this.user?.auth0Profile?.user_metadata?.reset_mfa || false;
  }

  constructor(
    private route: ActivatedRoute,
    private toasterService: ToasterService,
    private getUser: GetUserDetailsGQL,
    private changeUserMfaGQL: ChangeUserMfaGQL,
    private sessionService: SessionService,
    private routingStateService: RoutingStateService,
    private router: Router,
    private modalService: NgbModal,
    private encryptionService: EncryptionService,
    private currentUserService: CurrentUserService,
    private updateUserGQL: UpdateUserGQL,
    private deactivateUserGQL: DeactivateUserGQL,
    private changePasswordGQL: ChangePasswordGQL,
    private updateRoleGql: UpdateProfileUserRoleGQL,
    private store: Store,
  ) {}
  get canManageUsers() {
    return this.currentUserService.canManageUsers;
  }
  get userRole(): string {
    if (
      this.user &&
      this.user.auth0Profile &&
      this.user.auth0Profile.roles &&
      this.user.auth0Profile.roles.length > 0 &&
      this.user.auth0Profile.roles[0].name
    ) {
      return this.user.auth0Profile.roles[0].name;
    }
    return 'NO_ROLE';
  }

  get canShowProfile() {
    return !!(
      !this.loadingUser &&
      !this.deactivated &&
      this.user &&
      this.user.id
    );
  }

  get loadingUser() {
    return (
      this.loading ||
      this.loadingProfilePicture ||
      this.loadingContactDetails ||
      this.loadingUserDetails ||
      this.loadingUserSettings
    );
  }

  get isUserUnavailable() {
    return (this.deactivated || !this.user) && !this.loading;
  }

  get isCurrentUser() {
    return !!(
      this.user &&
      this.currentUserService.currentUser &&
      this.user.id === this.currentUserService.currentUser.id
    );
  }

  get canManageRoles() {
    return this.currentUserService.canUpdateRoles;
  }

  ngOnInit() {
    this.initVariables();
    this.initSubscriptions();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  initVariables() {
    this.currentUser = this.currentUserService.currentUser;
  }

  initSubscriptions() {
    this.subs.sink = this.route.params.subscribe((params) => {
      const encryptedId = params.userId;
      if (encryptedId) {
        const decryptedId = this.encryptionService.decrypt(encryptedId);
        this.initUser(decryptedId);
      }
    });

    this.isMFALicensed = this.currentUserService.isFeatureEnabled(
      CustomFeatures.UserMfa,
    );
  }

  editNameInput() {
    this.isEditingName = true;
    setTimeout(() => {
      this.userNameInput.nativeElement.select();
    });
  }

  async initUser(id: string) {
    this.loading = true;
    this.loaderMessage = 'FETCHING_USER';

    this.profilePicture = environment.assets.placeholderImage;

    this.getUser.fetch({ id }, { fetchPolicy: 'network-only' }).subscribe({
      next: ({ loading, data }) => {
        this.loading = loading;
        if (data.user?.displayName)
          this.breadcrumbService.set('@userManage', {
            skip: false,
            label: data.user.displayName,
            info: 'fat',
          });

        if (data.user?.auth0Profile) {
          this.user = cloneDeep(data.user) as IUser;

          this.deactivated = !this.user.active;
          this.initUserProfilePicture(this.user);
        }
      },
      error: () => {
        this.toasterService.error('FETCHING_USER_INFO_FAILED');
        this.loading = false;
      },
      complete: () => {
        this.currentTab = Tab.SECURITY;
        this.loading = false;
      },
    });
  }

  async initUserProfilePicture(user: IUser, updatedMediaUrl?: string) {
    if (updatedMediaUrl) {
      this.profilePicture = updatedMediaUrl;
      return;
    }

    if (user.profilePicture) {
      this.profilePicture = user.profilePicture.url;
    } else {
      this.profilePicture =
        user.auth0Profile && user.auth0Profile.picture
          ? user.auth0Profile.picture
          : environment.assets.placeholderImage;
    }
  }

  onActivateMfa() {
    return this.updateMfa(false);
  }
  onResetMfa() {
    return this.updateMfa(true);
  }

  updateMfa(reset: boolean) {
    if (this.user && this.user.auth0Profile && this.user.auth0Profile.user_id) {
      this.loaderMessage = 'SENDING_REQUEST';
      this.sendingChangePasswordRequest = true;
      this.loading = true;

      this.subs.sink = this.changeUserMfaGQL
        .mutate({
          input: {
            id: this.user.auth0Profile.user_id,
            enable: true,
            reset,
          },
        })
        .subscribe(
          ({ data }) => {
            if (!data || !data.changeUserMFA.isSuccessful) {
              // this.toasterService.error('PASSWORD_CHANGE_REQUEST_FAILED');
            } else {
              if (this.user) {
                this.user.auth0Profile = data?.changeUserMFA.auth0Profile;
              }
              // this.toasterService.success(data.changePassword.message);
            }
            this.sendingChangePasswordRequest = false;
            this.loading = false;
          },
          () => {
            this.toasterService.error('UNKNOWN_ERROR');
            this.sendingChangePasswordRequest = false;
            this.loading = false;
          },
        );
    }
  }

  async updateUserRole(newRole: Roles) {
    if (this.user) {
      const { data } = await lastValueFrom(
        this.updateRoleGql.mutate({
          input: {
            userId: this.user.id,
            profileId: this.currentUserService.getCurrentProfileId(),
            newRole,
          },
        }),
      );
      if (data?.updateProfileUserRole.isSuccessful) {
        // probably not needed...??
        this.initUser(this.user.id);
      } else {
        const message = data?.updateProfileUserRole.message;
        if (message) {
          this.toasterService.error();
        }
      }
    }
  }

  updateUserProfilePicture(media: IUploadedMediaArgs) {
    if (!this.user) {
      return;
    }

    const user = this.user;

    this.subs.sink = this.updateUserGQL
      .mutate({
        input: {
          id: user.id,
          profilePictureId: media.id,
        },
      })
      .subscribe({
        next: async ({ data }) => {
          if (data?.updateUser.isSuccessful) {
            await this.initUserProfilePicture(user, media.url);
            this.currentUserService.profilePictureUrl.emit(media.url);
          }
        },
        error: (error: ApolloError) => {
          error.graphQLErrors.forEach((gqlError) => {
            console.error('updateUserProfilePicture', gqlError);
            this.toasterService.handleGqlError(gqlError);
          });
        },
        complete: () => (this.loadingProfilePicture = false),
      });
  }
  onDeactivate() {
    const modalRef = this.modalService.open(UserDeactivateDialogComponent, {
      backdrop: 'static',
    });
    modalRef.componentInstance.user = this.user;
    modalRef.result
      .then(() => {
        this.deactivateUser();
      })
      .catch(() => {});
  }

  onChangePassword() {
    if (this.user && this.user.auth0Profile && this.user.auth0Profile.email) {
      this.loaderMessage = 'SENDING_REQUEST';
      this.sendingChangePasswordRequest = true;
      this.loading = true;

      this.subs.sink = this.changePasswordGQL
        .mutate({
          input: {
            email: this.user.auth0Profile.email,
          },
        })
        .subscribe(
          ({ data }) => {
            if (!data || !data.changePassword.isSuccessful) {
              this.toasterService.error('PASSWORD_CHANGE_REQUEST_FAILED');
            } else {
              this.toasterService.success(data.changePassword.message);
            }
            this.sendingChangePasswordRequest = false;
            this.loading = false;
          },
          () => {
            this.toasterService.error('UNKNOWN_ERROR');
            this.sendingChangePasswordRequest = false;
            this.loading = false;
          },
        );
    }
  }

  deactivateUser() {
    if (this.user && this.user.id) {
      this.deactivatingUser = true;
      this.loading = true;

      this.loaderMessage = 'DEACTIVATING_USER';
      this.subs.sink = this.deactivateUserGQL
        .mutate({ id: this.user.id })
        .subscribe(
          ({ data }) => {
            if (
              this.currentUserService.currentUser &&
              data &&
              data.deactivateUser.isSuccessful
            ) {
              this.deactivated = true;
              this.toasterService.success('USER_DEACTIVATED');

              if (
                this.user &&
                this.user.id === this.currentUserService.currentUser.id
              ) {
                this.store.dispatch(
                  userActions.setActiveUser({ user: this.user }),
                );
                this.currentUserService.currentUser.active = false;
                setTimeout(() => {
                  this.sessionService.logout('cms');
                }, 1000);
              } else {
                this.router.navigateByUrl(
                  this.routingStateService.getPreviousUrl(),
                );
              }
            } else {
              this.toasterService.error('DEACTIVATE_USER_ERROR');
            }
            this.deactivatingUser = false;
            this.loading = false;
          },
          () => {
            this.toasterService.error('DEACTIVATE_USER_ERROR');
            this.deactivatingUser = false;
            this.loading = false;
          },
        );
    }
  }
}
