import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  GetRolesForUserInvitesGQL,
  GetRolesForUserInvitesQuery,
  Maybe,
} from '@designage/gql';
import { SubSink } from 'subsink';
import { IInviteUserForm } from '@desquare/interfaces';
import { Roles } from '@desquare/enums';
import { CurrentUserService } from '@desquare/services';
import { FormDialogComponent } from '@desquare/components/common/src/modals/form-dialog.component';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { LoaderComponent } from '@desquare/components/common/src/loader/loader.component';

@Component({
  standalone: true,
  imports: [
    ReactiveFormsModule,
    TranslateModule,
    NgbPopoverModule,
    FormDialogComponent,
    LoaderComponent,
  ],
  selector: 'app-invite-user-dialog',
  template: `<app-form-dialog
    closeButtonText="SEND"
    dismissButtonText="CANCEL"
    headerText="INVITE_USER"
    [valid]="isFormValid"
    [pristine]="isFormPristine"
    [values]="values"
  >
    @if (loading) {
      <app-loader [message]="loaderMessage" />
    } @else {
      <form id="inviteForm" [formGroup]="inviteForm">
        <div class="row">
          <div class="col-12 form-group">
            <label for="email">{{ 'EMAIL' | translate }}:</label>
            @if (
              inviteForm &&
              !inviteForm.controls['email'].pristine &&
              inviteForm.controls['email'].hasError('required')
            ) {
              <span class="error">
                {{ 'NAME_IS_REQUIRED' | translate }}
              </span>
            }
            <input
              type="text"
              class="form-control form-control-dark"
              formControlName="email"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <label for="roles">{{ 'ROLE' | translate }}:</label>
            <select
              formControlName="roleId"
              class="form-select form-select-sm form-select-dark role-select"
            >
              <option [ngValue]="null" disabled>
                {{ 'SELECT_A_ROLE' | translate }}
              </option>
              @for (role of roles; track role.id) {
                <option [value]="role.id">
                  {{ role.name ?? '' | translate }}
                </option>
              }
            </select>
          </div>
        </div>
      </form>
    }
  </app-form-dialog> `,
})
export class InviteUserDialogComponent implements OnInit, OnDestroy {
  private subs = new SubSink();

  values!: Maybe<IInviteUserForm>;
  loading = false;
  loaderMessage = 'LOADING';
  isFormValid = false;
  isFormPristine = true;
  inviteForm!: FormGroup;

  roles!: GetRolesForUserInvitesQuery['roles'];

  constructor(
    private formBuilder: FormBuilder,
    private rolesGQL: GetRolesForUserInvitesGQL,
    private currentUserService: CurrentUserService,
  ) {}

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  ngOnInit() {
    this.initForm();
    this.setFormState();
    this.initVariables();
  }

  initForm() {
    this.inviteForm = this.formBuilder.group({
      email: [null, [Validators.required, Validators.email]],
      roleId: [null, Validators.required],
    });
  }

  setFormState() {
    this.subs.sink = this.inviteForm.valueChanges.subscribe(() => {
      this.isFormPristine = this.inviteForm.pristine;
      this.isFormValid = this.inviteForm.valid;
      this.values = this.inviteForm.value;
    });
  }

  initVariables() {
    this.subs.sink = this.rolesGQL
      .fetch()
      .subscribe(({ loading, data: { roles } }) => {
        this.loading = loading;
        this.roles = !this.currentUserService.isSuperAdmin
          ? roles.filter((x) => x.name !== Roles.SUPER_ADMIN)
          : roles;
      });
  }
}
