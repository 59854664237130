import {
  Component,
  ChangeDetectionStrategy,
  signal,
  inject,
  computed,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SlidePanelComponent } from '@designage/app/shared/slide-panel/slide-panel.component';
import {
  DeviceDataService,
  EncryptionService,
  SlidePanelService,
} from '@desquare/services';
import { DeviceManageComponent } from '../device-manage/device-manage.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DevicesStore } from '@desquare/stores';

@Component({
  standalone: true,
  imports: [SlidePanelComponent, DeviceManageComponent],
  selector: 'app-device-manage-slider',
  template: `<app-slide-panel
    (closePanel)="closeDeviceManagePanel()"
    [fullscreenTooltip]="'TOGGLE_DEVICE_TABLE'"
    [closePanelTooltip]="'CLOSE_THIS_DEVICE'"
    [fullscreen]="true"
    [openWidth]="20"
    [panelContent]="'deviceManage'"
  >
    @if (device()) {
      <app-device-manage
        #deviceManageComponent
        class="device-details"
        [device]="device()!"
        (closeClick)="closeDeviceManagePanel()"
        (reloadParent)="deviceDataService.refreshDevicesFromApi()"
      />
    }
  </app-slide-panel> `,
  styles: [
    `
      .device-screenshot {
        max-height: 30rem;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeviceManageSliderComponent {
  route = inject(ActivatedRoute);
  router = inject(Router);
  encryptionService = inject(EncryptionService);
  deviceDataService = inject(DeviceDataService);
  devicesStore = inject(DevicesStore);

  params$ = this.route.params.pipe(takeUntilDestroyed());
  deviceId = signal<string>('');

  constructor() {
    this.params$.subscribe((params) => {
      const { deviceId } = params;
      this.deviceId.set(this.encryptionService.decrypt(deviceId));
    });
    inject(SlidePanelService).setPanelComponentId(this.deviceId());
  }
  device = computed(() =>
    this.devicesStore.devices().find((x) => x.id === this.deviceId()),
  );

  closeDeviceManagePanel() {
    console.log('closeDeviceManagePanel');

    // this navigates to the current path before 2 slashes, example:
    // current path: 'devices/manage/<channelId>
    // target path: 'devices
    this.router.navigate(['../../'], { relativeTo: this.route });
  }
}
