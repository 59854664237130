<div class="d-flex flex-column h-100">
  <div class="d-flex justify-content-between align-items-center">
    <h4 class="flex-grow-1">{{ 'SELECT_USERS' | translate }}</h4>
    <button
      class="btn waves-effect waves-light mt-2 me-2 btn-outline-primary"
      (click)="openInviteDialog()"
    >
      {{ 'INVITE' | translate }}
    </button>
  </div>

  <designage-data-table
    configId="user-selection"
    [data]="users"
    [columns]="desColumns"
    [columnSelector]="false"
    [customComponent]="[removeButton, mfaSecurity]"
    (rowClick)="onRowClick($event)"
    [rowActiveInSlidingPanel]="selectedUserId()"
  >
  </designage-data-table>
</div>
<ng-template #removeButton let-row let-removeUser="action">
  <span class="text-truncate">
    <button
      class="btn btn-sm btn-outline-danger"
      (click)="$event.stopPropagation(); onRemoveUserButtonClick(row)"
    >
      {{ 'REMOVE_USER' | translate }}
    </button>
  </span>
</ng-template>
<ng-template #mfaSecurity let-row>
  <span class="text-truncate">
    @if (mfaInfo(row); as mfaResponse) {
      @if (mfaResponse === 'MFA_ACTIVE') {
        <div class="text-truncate">
          <span class="badge bg-success me-2">{{ 'ACTIVE' | translate }} </span
          >{{ 'MFA_ACTIVE' | translate }}
        </div>
      }
      @if (mfaResponse === 'MFA_INACTIVE') {
        <div class="text-truncate">
          <span class="badge bg-warning me-2"
            >{{ 'INACTIVE' | translate }} </span
          >{{ 'MFA_INACTIVE' | translate }}
        </div>
      }
      @if (mfaResponse === 'MFA_NOT_LICENSED') {
        <div class="text-secondary text-truncate">
          {{ 'MFA_NOT_LICENSED' | translate }}
        </div>
      }
      @if (mfaResponse === 'MFA_RESET') {
        <div class="text-secondary text-truncate">
          {{ 'MFA_RESET_PENDING' | translate }}
        </div>
      }
    }
  </span>
</ng-template>

<!-- Channel Manage Slide Panel -->
@if (enableSlidePanel) {
  <router-outlet />
}
